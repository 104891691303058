import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import moment from 'moment'

export default function addProduct() {
  const productAdd = ref({
    title: '',
    slug: '',
  })
  const idProduct = ref(null)
  const imagesId = []
  const images = []
  const imageHeader = ref([])
  const imagesHowItWork = ref([])
  const imageOverview = ref([])
  const arrayRegion = [
    {
      region_id: '',
      from_price: '',
    },
  ]
  const arrayDescription = [
    {
      region_id: '',
      title: '',
      description: '',
    },
  ]
  const countDescription = ref(1)
  const countRegion = ref(1)
  const from_price = ref(null)
  const toPrice = ref(null)
  const loading = ref(false)
  const regionId = ref(null)
  idProduct.value = router.currentRoute.params.id
  setTimeout(() => {
    loading.value = true
    store
      .dispatch('product/fetchProduct', { id: idProduct.value })
      .then(response => {
        regionId.value ={
          label: store.state.app.regionOptions.find(x => x.value === response.body.region_id).label,
          value: response.body.region_id,
        }
        productAdd.value = {
          region_id: response.body.region_id,
          title: response.body.title,
          tag_ids: response.body.tags.map(x => ({
            label: x.name,
            value: x.id,
          })),
          category_ids: response.body.categories.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          product_roles: response.body.product_roles.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          product_bussiness_challenges: response.body.product_bussiness_challenges.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          status: response.body.status,
          from_price: response.body.price ? response.body.price.from_price : 0,
          slug: response.body.slug,
          startDateTime: moment(response.body.metadata.metadata.startDatetime).format('YYYY-MM-DD hh:mm'),
        }
        // Fetch enities by region
        if (productAdd.value.region_id) {
          const queryParams = {
            region_ids: [productAdd.value.region_id],
          }
          store.dispatch('bussiness/fetchBussiness', queryParams)
          store.dispatch('role/fetchRole', queryParams)
          const queryParamsCate = {
            region: productAdd.value.region_id,
          }
          store.dispatch('product/fetchCategories', queryParamsCate)
        }
        if (response.body.images.length > 0) {
          for (let i = 0; i < response.body.images.length; i += 1) {
            if (response.body.images[i].type === 'header_image') {
              imageHeader.value.push(response.body.images[i])
            } else if (response.body.images[i].type === 'how_it_work') {
              imagesHowItWork.value.push(response.body.images[i])
            }
          }
        }
        arrayDescription.shift()
        countDescription.value = response.body.metadata.metadata.regions_form.length
        response.body.metadata.metadata.regions_form.forEach(e => {
          arrayDescription.push(e)
        })
        // Get image overview
        if (response.body.metadata.metadata.imageOverview) {
          imageOverview.value.push(
            response.body.metadata.metadata.imageOverview,
          )
        }
        loading.value = false
      })
      .catch(error => {
        loading.value = false
        console.log(error)
      })
  }, 0)

  const updateItemFormDescription = (index, val) => {
    const region_id = val
    arrayDescription[index].region_id = region_id
  }
  const updateItemFormRegion = (index, val) => {
    const region_id = val
    arrayRegion[index].region_id = region_id
  }
  return {
    productAdd,
    images,
    from_price,
    toPrice,
    arrayDescription,
    arrayRegion,
    countDescription,
    countRegion,
    updateItemFormRegion,
    updateItemFormDescription,
    imagesId,
    imageHeader,
    imagesHowItWork,
    imageOverview,
    loading,
    regionId,
  }
}
